<template>
  <div>
    <!--  Cover Section -->
    <section
      v-if="doShowCover"
      ref="cover"
      class="section cover-section">
      <CoverTransition>
        <div class="section-bg program-bg"></div>
      </CoverTransition>
      <SplitTitleTransition>
        <!-- BUSINESS ENABLER DREAMPLUS -->
        <h2 class="split-title">BUSINESS ENABLER DREAMPLUS</h2>
      </SplitTitleTransition>
    </section>
    <!--  // Cover Section -->

    <!-- Title Section -->
    <!-- *- Cover를 표시하지 않는 경우 top 클래스를 추가 -->
    <section
      class="title-section"
      :class="{ top: !doShowCover }"
    >
      <div class="title-wrap">
        <!-- WITH HANWHA PROGRAM -->
        <h2>WITH HANWHA PROGRAM</h2>
        <div class="title-desc" v-html="$t('content.programs.withh.txt01')"></div>
        <div class="contact">
          {{$t('content.programs.withh.txt02')}}:
          <ExternalLink
            to="mailto:accelerating@dreamplus.asia"
          >accelerating@dreamplus.asia</ExternalLink>
        </div>
      </div>
    </section>
    <!-- // Title Section -->

    <!-- 주요 프로그램 -->
    <section class="inner-section">
      <div class="inner-lg">
        <h3
          :class="{ center: true, kor: $i18n.locale === 'ko' }"
          v-html="$t('content.programs.withh.txt03')"></h3>
        <ul class="program-list">
          <!-- Meet Up -->
          <li>
            <img src="@/assets/images/programs/photo-meetup.jpg" class="program-photo" />
            <dl class="program" data-aos="fade-up">
              <dt>Meet Up</dt>
              <dd v-html="$t('content.programs.withh.txt04')"></dd>
            </dl>
          </li>

          <!-- PoC -->
          <li>
            <img src="@/assets/images/programs/photo-poc.jpg" class="program-photo" />
            <dl class="program" data-aos="fade-up">
              <dt>PoC</dt>
              <dd v-html="$t('content.programs.withh.txt05')"></dd>
            </dl>
          </li>

          <!-- TENANTS -->
          <li>
            <img src="@/assets/images/programs/photo-tenants.jpg" class="program-photo" />
            <dl class="program" data-aos="fade-up" data-aos-delay="500">
              <dt>TENANTS</dt>
              <dd v-html="$t('content.programs.withh.txt06')"></dd>
            </dl>
          </li>

          <!-- DEMODAY -->
          <li>
            <img src="@/assets/images/programs/photo-demoday.jpg" class="program-photo" />
            <dl class="program" data-aos="fade-up" data-aos-delay="500">
              <dt>DEMODAY</dt>
              <dd v-html="$t('content.programs.withh.txt07')"></dd>
            </dl>
          </li>

        </ul>
      </div>
    </section>
    <!-- // 주요 프로그램 -->

    <!-- 프로그램 혜택 -->
    <section class="grey-section">
      <div class="inner-lg">
        <h3
          :class="{ center: true, kor: $i18n.locale === 'ko' }"
         v-html="$t('content.programs.withh.txt08')"></h3>

        <StaggerTransition
          id="with-hanwha-benefits"
        >
          <ul class="icon-card-list ani-stagger">
            <!-- BUSINESS CONNECTING -->
            <li>
              <dl class="icon-card startup">
                <dt>BUSINESS CONNECTING</dt>
                <dd v-html="$t('content.programs.withh.txt09')"></dd>
              </dl>
            </li>

            <!-- NETWORKING -->
            <li>
              <dl class="icon-card network">
                <dt>NETWORKING</dt>
                <dd v-html="$t('content.programs.withh.txt10')"></dd>
              </dl>
            </li>

            <!-- PR & MARKETING -->
            <li>
              <dl class="icon-card event-hall">
                <dt>PR & MARKETING</dt>
                <dd v-html="$t('content.programs.withh.txt11')"></dd>
              </dl>
            </li>

          </ul>
        </StaggerTransition>
      </div>
    </section>
    <!-- // 프로그램 혜택 -->

    <!-- 프로그램 참여 사례 -->
    <section class="case-section">
      <div class="inner-lg">
        <h3
          :class="{ kor: $i18n.locale === 'ko' }"
          v-html="$t('content.programs.withh.txt12')"></h3>
        <div class="case-list-wrap">
          <ul class="case-list">
            <!-- 프로그램 참여 사례 1 : 애자일소다 -->
            <li>
              <div class="case">
                <div class="case-photo-area" data-aos="fade-right">
                  <img
                    src="@/assets/images/programs/photo-case-1.jpg"
                    class="case-photo"
                    alt="애자일소다 대표 최대우 님"
                  />
                </div>
                <div data-aos="fade-left">
                  <h4 v-html="$t('content.programs.withh.txt13')"></h4>
                  <span class="participant" v-html="$t('content.programs.withh.txt14')"></span>
                  <p class="case-review" v-html="$t('content.programs.withh.txt15')"></p>
                  <span class="name" v-html="$t('content.programs.withh.txt16')"></span>
                </div>
              </div>
            </li>
            <!-- // 프로그램 참여 사례 1 : 애자일소다 -->

            <!-- 프로그램 참여 사례 2 : 펫트너 -->
            <li>
              <div class="case">
                <div class="case-photo-area" data-aos="fade-left">
                  <img
                    src="@/assets/images/programs/photo-case-2.jpg"
                    class="case-photo"
                    alt="펫트너 대표 최가림 님"
                  />
                </div>
                <div data-aos="fade-right">
                  <h4 v-html="$t('content.programs.withh.txt17')"></h4>
                  <span class="participant" v-html="$t('content.programs.withh.txt18')"></span>
                  <p class="case-review" v-html="$t('content.programs.withh.txt19')"></p>
                  <span class="name" v-html="$t('content.programs.withh.txt20')"></span>
                </div>
              </div>
            </li>
            <!-- // 프로그램 참여 사례 2 : 펫트너 -->

          </ul>
        </div>
      </div>
    </section>
    <!-- // 프로그램 참여 사례 -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import ExternalLink from '@/components/base/ExternalLink.vue';
import CoverTransition from '@/components/transition/CoverTransition.vue';
import SplitTitleTransition from '@/components/transition/SplitTitleTransition.vue';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';

/**
 * Programs > With Hanwha
 */
export default {
  name: 'ProgramsWithHanwha',

  mixins: [
    mixinHelperUtils,
  ],

  components: {
    ExternalLink,
    CoverTransition,
    SplitTitleTransition,
    StaggerTransition,
  },

  computed: {
    /**
     * Cover 표시 여부
     * @see programsRouter.js
     */
    doShowCover() {
      return this.$route.query.with === 'cover';
    },
  },
};
</script>
